<template>
  <v-container tag="section" style="height: 100%" class="d-flex flex-column justify-center">
    <div v-if="loading" class="d-flex flex-column align-center justify-center">
      <v-progress-linear indeterminate color="info" />
      <h3>Verificando avaliações</h3>
    </div>
    <template v-else>
      <Invalid v-if="showModalInvalid" />
      <template v-else>
        <v-fade-transition>
          <h4 v-if="listEvaluations && listEvaluations.nome"
            class="text-h4 text-left font-weight-medium mb-3 ml-4 primary--text">
            Olá {{ listEvaluations.nome | nomeAbreviado() }}
          </h4>
        </v-fade-transition>
        <v-card min-width="70%" max-width="100%">
          <v-card-title>{{ tiposAvaliacoes[avaliacaoSelected].title }}</v-card-title>
          <v-card-subtitle>
            {{ tiposAvaliacoes[avaliacaoSelected].subtitle }}
          </v-card-subtitle>
          <v-card-text v-if="listEvaluations && listEvaluations.data_final">
            <div class="d-flex justify-end">
              <h4 class="primary--text">
                <v-icon color="primary">mdi-clock</v-icon> Data final:
                <span class="font-weight-regular">{{
                  listEvaluations.data_final | dateFormatBr()
                }}</span>
              </h4>
            </div>
          </v-card-text>
          <v-card-text>
            <v-btn class="text-xs text-sm-subtitle-1" v-if="validateInformations && validateInformations.gestor == '1'"
              @click="avaliacaoSelected = avaliacaoSelected == 'com' ? 'tec' : 'com'" text color="accent">{{
                tiposAvaliacoes[avaliacaoSelected].textButton }}
              <v-icon class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text>
            <component :is="tiposAvaliacoes[avaliacaoSelected].component"></component>
          </v-card-text>
        </v-card>
      </template>
    </template>
  </v-container>
</template>

<script>
import Invalid from '@/modules/avd/avaliacoes/components/avaliacaoComponents/Invalid.vue'

export default {
  name: "AvaliacoesView",
  components: {
    Invalid
  },
  created() {
    const data = {
      uuid_colaborador: this.$route.params.uuid,
      uuid_bateria: this.$route.params.battery_uuid
    }

    this.$store.dispatch('avdFormularios/validateFormEvaluation', data).then(response => {
      if (response) {
        this.validateInformations = response
      } else {
        this.showModalInvalid = true
      }
    }).catch(() => {
      store.dispatch("snackBarModule/openSnackBar", {
        color: "error",
        timeout: 5000,
        text: "Ocorreu um erro inesperado ao carregar as avaliações. Por favor, tente novamente mais tarde.",
      });
    }).finally(() => {
      this.loading = false
    })
  },
  data() {
    return {
      tiposAvaliacoes: {
        com: {
          title: 'Confira Suas Avaliações de Desempenho',
          subtitle: 'Escolha por qual avaliação você deseja começar primeiro.',
          textButton: 'Avaliações técnicas',
          component: () => import('../components/avaliacoesComponents/ListaAvaliacoes.vue')
        },
        tec: {
          title: 'Avaliações técnicas dos colaboradores',
          subtitle: 'Responda as avaliações técnicas de seus colaboradores.',
          textButton: 'Avaliações comportamentais',
          component: () => import('../components/avaliacoesComponents/ListaAvaliacoesTecnica.vue')
        },
      },
      avaliacaoSelected: 'com',

      loading: true,
      showModalInvalid: false,
      validateInformations: null
    }
  },
  computed: {
    listEvaluations: {
      get() {
        return this.$store.getters["avdFormularios/getFormsOfCollaborator"];
      },
    },
  },
};
</script>

<style></style>
