<template>
  <div class="d-flex flex-column align-center justify-center">
    <h1 class="text-h5 black--text font-weight-bold text-center">
      Avaliação inválida ou fechada
    </h1>
    <p class="text-subtitle-2 my-2 text-center">
      A avaliação está inválida ou fechada. Por favor, tente novamente mais
      tarde ou entre em contato com o suporte.
    </p>
    <v-img :src="require('@/assets/images/3d-characters/error.png')" alt="imagem de erro" contain height="25em" width="30em" />
  </div>
</template>

<script>
export default {
  name: "Invalid",
};
</script>

<style></style>
